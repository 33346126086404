import { Outlet, createRootRoute } from '@tanstack/react-router'
import { FC } from 'react'

const RootLayout: FC = () => {
  return <Outlet />
}
RootLayout.displayName = 'RootLayout'

export const Route = createRootRoute({
  component: RootLayout,
})
