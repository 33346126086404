import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import React, { ComponentProps, FC, useCallback, useMemo } from 'react'

import { ErrorFallback } from '@/components/errors/errorFallback'
import { ApiProvider, useApi } from '@/contexts/ApiProvider'
import {
  FeatureFlagServiceProvider,
  useFeatureFlagService,
} from '@/contexts/FeatureFlagServiceProvider'
import { NewTaskProvider } from '@/contexts/NewTaskProvider'
import { OidcAuthProvider, useOIDCAuth } from '@/contexts/OidcAuthContext'
import { ConfigurationVariables } from '@/lib/configurationVariablesSchemaValidator'
import { logger } from '@/lib/logger'
import { router as appRouter } from '@/router'

interface RouterProviderProperties
  extends Partial<ComponentProps<typeof RouterProvider>> {
  context?: {
    queryClient: QueryClient
  }
}

interface Properties {
  configurationVariables: ConfigurationVariables
  queryClientProviderProps?: Partial<ComponentProps<typeof QueryClientProvider>>
  routerProviderProps?: RouterProviderProperties
}

const RouterProviderWithContext: FC<ComponentProps<typeof RouterProvider>> = ({
  context: passedContext,
  ...rest
}) => {
  const api = useApi()
  const featureFlag = useFeatureFlagService()
  const { getUser } = useOIDCAuth()

  const context = useMemo(
    () => ({
      api,
      featureFlag,
      getUser,
      ...passedContext,
    }),
    [api, featureFlag, getUser, passedContext]
  )

  return <RouterProvider context={context} {...rest} />
}
RouterProviderWithContext.displayName = 'RouterProviderWithContext'

export const Entry: FC<Properties> = ({
  configurationVariables,
  queryClientProviderProps,
  routerProviderProps: routerProviderProperties = {},
}) => {
  const {
    context = {
      queryClient: new QueryClient(),
    },
    router = appRouter,
    ...restRouterProviderProperties
  } = routerProviderProperties
  const fullContext = useMemo(
    () => ({
      ...context,
      configurationVariables,
    }),
    [context, configurationVariables]
  )
  const defaultOnCatch = useCallback((error: unknown) => {
    logger.error(error)
  }, [])

  return (
    <React.StrictMode>
      <QueryClientProvider
        client={context.queryClient}
        {...queryClientProviderProps}
      >
        <FeatureFlagServiceProvider
          configurationVariables={configurationVariables}
        >
          <OidcAuthProvider configurationVariables={configurationVariables}>
            <ApiProvider>
              <NewTaskProvider>
                <RouterProviderWithContext
                  context={fullContext}
                  defaultErrorComponent={ErrorFallback}
                  defaultOnCatch={defaultOnCatch}
                  router={router}
                  {...restRouterProviderProperties}
                />
              </NewTaskProvider>
            </ApiProvider>
          </OidcAuthProvider>
        </FeatureFlagServiceProvider>
      </QueryClientProvider>
    </React.StrictMode>
  )
}
Entry.displayName = 'Entry'
