/* v8 ignore next 18 */
import { FC } from 'react'
import ReactDOM from 'react-dom/client'

import {
  ConfigurationVariablesProvider,
  useConfigurationVariables,
} from '@/contexts/ConfigurationVariablesProvider'
import { Entry } from '@/entry'

import '@/global.css'
import '@/newrelic.ts'
import 'react-toastify/dist/ReactToastify.css'

const rootElement = document.querySelector('#root')
if (!rootElement) {
  throw new Error('No root!')
}

const EntryWithConfig: FC = () => {
  const configurationVariables = useConfigurationVariables()
  if (!configurationVariables) {
    return
  }

  return <Entry configurationVariables={configurationVariables} />
}
EntryWithConfig.displayName = 'EntryWithConfig'

ReactDOM.createRoot(rootElement).render(
  <ConfigurationVariablesProvider>
    <EntryWithConfig />
  </ConfigurationVariablesProvider>
)
