import { QueryClient } from '@tanstack/react-query'
import { createRouter, ParseRoute } from '@tanstack/react-router'
import { ComponentType, HTMLAttributes } from 'react'

import { Api } from '@/contexts/ApiProvider'
import { FeatureFlagService } from '@/contexts/FeatureFlagServiceProvider'
import { OidcAuthContextState } from '@/contexts/OidcAuthContext'
import { ConfigurationVariables } from '@/lib/configurationVariablesSchemaValidator'
import { IconComponent } from '@/lib/types'
import { routeTree } from '@/routeTree.gen'

export const router = createRouter({ routeTree })
export type ValidRoute = ParseRoute<typeof routeTree>['fullPath']

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }

  interface RouteContext {
    queryClient: QueryClient
    api: Api
    getUser: OidcAuthContextState['getUser']
    featureFlag: FeatureFlagService
    configurationVariables: ConfigurationVariables
  }

  interface Route {
    interloom?: {
      Breadcrumb?: ComponentType<HTMLAttributes<HTMLSpanElement>>
      NavigationIcon?: IconComponent
    }
  }
}
