import { createFileRoute, Navigate } from '@tanstack/react-router'
import { FC, useCallback } from 'react'

import { InterloomLogo } from '@/components/icons/InterloomLogo'
import { Button } from '@/components/ui/button/Button'
import { LoadingSpinner } from '@/components/ui/loading-spinner/LoadingSpinner'
import { useOIDCAuth } from '@/contexts/OidcAuthContext'
import { cn } from '@/lib/utils'

const Signin: FC = () => {
  const { loading, signIn, user } = useOIDCAuth()

  const syncSignIn = useCallback(() => {
    void signIn()
  }, [signIn])

  if (user) {
    return <Navigate to="/" />
  }

  return (
    <div className="flex h-screen items-stretch">
      <div className="hidden flex-[6] items-center justify-center bg-mono-ink-strong text-mono-paper sm:flex">
        <InterloomLogo className="size-40" />
      </div>
      <div className="flex flex-[4] items-center justify-center bg-mono-paper-darker">
        <div className="w-full max-w-[320px] px-4 text-center">
          <h1 className="text-2xl font-semibold text-mono-ink-strong">
            Welcome
          </h1>
          <p className="mt-2 text-xs font-medium leading-4 text-mono-ink-subtle">
            Press the button below to begin
          </p>
          <Button
            variant={'solid'}
            disabled={loading}
            className="mt-7 w-full"
            onClick={syncSignIn}
          >
            <span className="relative block">
              <InterloomLogo className="size-6" />
              <LoadingSpinner
                className={cn(
                  'absolute top-0 size-6 opacity-0 ease-out',
                  loading && 'opacity-1'
                )}
              />
            </span>
            Sign in
          </Button>
          <p className="mt-6 text-xs font-medium leading-4 text-mono-ink-subtle">
            By clicking continue, you agree to our{' '}
            <a
              href="https://interloom.io/en"
              className="text-sm font-normal underline"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://interloom.io/en"
              className="text-sm font-normal underline"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
Signin.displayName = 'Signin'

export const Route = createFileRoute('/_auth/signin')({
  component: Signin,
})
