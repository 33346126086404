import { z } from 'zod'

export const configurationVariablesSchemaValidator = z.object({
  env: z.object({
    PUBLIC_UNLEASH_TOKEN: z.string(),
    PUBLIC_UNLEASH_URL: z.string(),
    PUBLIC_ZITADEL_CLIENT_ID: z.string(),
    PUBLIC_ZITADEL_ISSUER_URL: z.string(),
    PUBLIC_ZITADEL_ORGANIZATION_ID: z.string(),
  }),
})

export type ConfigurationVariables = z.infer<
  typeof configurationVariablesSchemaValidator
>

export const configSchemaEnvironmentKeys = Object.keys(
  configurationVariablesSchemaValidator.shape.env.shape
) as Array<
  keyof (typeof configurationVariablesSchemaValidator.shape.env)['shape']
>
