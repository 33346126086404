import isFunction from 'lodash/isFunction'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { EVENTS, UnleashClient } from 'unleash-proxy-client'

import { ConfigurationVariables } from '@/lib/configurationVariablesSchemaValidator'
import { logger } from '@/lib/logger'
import { makeErrorFromUnknown } from '@/lib/utils'

export interface FeatureFlagService {
  isEnabled: (
    flagName: string,
    fallback: boolean | (() => boolean | Promise<boolean>)
  ) => Promise<boolean>
}

const FeatureFlagServiceContext = createContext<FeatureFlagService | undefined>(
  undefined
)
FeatureFlagServiceContext.displayName = 'FeatureFlagServiceContext'

export const useFeatureFlagService = () => {
  const context = useContext(FeatureFlagServiceContext)
  if (!context) {
    throw new Error('useFeatureFlagService used without provider!')
  }

  return context
}

interface Properties extends PropsWithChildren {
  configurationVariables: ConfigurationVariables
}

export const FeatureFlagServiceProvider: FC<Properties> = ({
  children,
  configurationVariables: {
    env: { PUBLIC_UNLEASH_TOKEN, PUBLIC_UNLEASH_URL },
  },
}) => {
  const unleashClientPromise = useMemo(
    async () =>
      await new Promise<UnleashClient | undefined>((resolve) => {
        if (import.meta.env.PUBLIC_DISABLE_FEATURE_FLAG_SERVICE === 'true') {
          const result = undefined
          resolve(result)
          return
        }
        const unleash = new UnleashClient({
          appName: 'webapp',
          clientKey: PUBLIC_UNLEASH_TOKEN,
          refreshInterval: 15,
          url: PUBLIC_UNLEASH_URL,
        })

        void unleash.start()
        if (unleash.isReady()) {
          resolve(unleash)
          return
        }

        unleash.on(EVENTS.READY, () => {
          resolve(unleash)
        })
        unleash.on(EVENTS.ERROR, (maybeError: unknown) => {
          const error = makeErrorFromUnknown(maybeError)
          logger.error(error)
          const result = undefined
          resolve(result)
        })
      }),
    [PUBLIC_UNLEASH_TOKEN, PUBLIC_UNLEASH_URL]
  )

  const isEnabled: FeatureFlagService['isEnabled'] = useCallback(
    async (flagName, fallback) => {
      const unleashClient = await unleashClientPromise

      if (!unleashClient) {
        return isFunction(fallback) ? await fallback() : fallback
      }

      return unleashClient.isEnabled(flagName)
    },
    [unleashClientPromise]
  )

  return (
    <FeatureFlagServiceContext.Provider
      value={{
        isEnabled,
      }}
    >
      {children}
    </FeatureFlagServiceContext.Provider>
  )
}
FeatureFlagServiceProvider.displayName = 'FeatureFlagServiceProvider'
